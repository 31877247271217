 /* Estilo específico para dispositivos com telas maiores, como tablets, notebooks, TVs e monitores */
 @media (min-width: 768px) {

   /* Tamanhos de tela para tablets e dispositivos móveis maiores */
   .custom-container-hallapp {
     /* Estilos específicos para tablets e telas maiores */
     width: 60%;
   }

   .custom-container-hallapp-wrapper {
     display: flex;
     justify-content: center;
     align-items: center;
     width: 100%;
   }
 }

 @media (min-width: 1024px) {

   /* Tamanhos de tela para notebooks e monitores */
   .custom-container-hallapp {
     /* Estilos específicos para notebooks e monitores */
     width: 60%;
   }

   .custom-container-hallapp-wrapper {
     display: flex;
     justify-content: center;
     align-items: center;
     width: 100%;
   }
 }

 @media (min-width: 1200px) {

   /* Tamanhos de tela para TVs e grandes monitores */
   .custom-container-hallapp {
     /* Estilos específicos para TVs e grandes monitores */
     width: 60%;
   }

   .custom-container-hallapp-wrapper {
     display: flex;
     justify-content: center;
     align-items: center;
     width: 100%;
   }
 }