h1 {
    font-size: 24px;
    margin-bottom: 20px;
}

p {
    font-size: 16px;
    margin-bottom: 20px;
}

ol {
    text-align: left;
    margin: 0 auto;
    padding: 0;
    list-style: none;
    font-size: 16px;
}

// li {
//     margin-bottom: 15px;
//     position: relative;
//     padding-left: 30px;
// }

// li::before {
//     content: '✔';
//     color: #4caf50;
//     position: absolute;
//     left: 0;
//     top: 0;
//     font-size: 18px;
// }

@media (max-width: 600px) {
    .container {
        padding: 20px;
    }

    h1 {
        font-size: 20px;
    }
    p,
    ol {
        font-size: 14px;
    }
}
