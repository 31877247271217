@import '../../../colors.scss';

.card-command {
    background-color: white;
    padding: 20px;
    border-radius: 12px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    position: relative;
}

.create-date {
    margin-top: 0 !important;
    font-size: 13px !important;
    color: #7d7d7d;
}

.card-command::before,
.card-command::after {
    content: "";
    position: absolute;
    width: 24px;
    height: 24px;
    background-color: $page-back-color;
    top: 70px !important;
    border-radius: 50%;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
}

.dashed-hr {
    border: none;
    border-top: 0.2px dashed #000;
    margin-top: 0px;
}

.title {
    font-size: 16px;
}

.card-command::before {
    left: -12px;
}

.card-command::after {
    right: -12px;
}


.title {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 15px;
}

.info {
    margin-bottom: 15px;
}

.info p {
    font-size: 16px;
    margin: 5px 0;
}

.info .label {
    font-size: 14px;
    color: #7d7d7d;
}


.swipe-delete-command {
    display: flex;
    flex-direction: column;
    /* Empilha o ícone e o texto verticalmente */
    align-items: center;
    /* Centraliza horizontalmente */
    justify-content: center;
    /* Centraliza verticalmente no contêiner */
    height: 100%;
    /* Faz com que o div ocupe toda a altura disponível do contêiner pai */
    padding: 10px;
    /* Adiciona algum espaçamento interno */
    background-color: rgb(255, 132, 102);
    ;
    /* Exemplo de cor de fundo */
    border-radius: 4px;
    /* Adiciona bordas arredondadas, se desejar */
    cursor: pointer;
    /* Adiciona um cursor de mão para indicar que é clicável */
}

.swipe-edit-command {
    display: flex;
    flex-direction: column;
    /* Empilha o ícone e o texto verticalmente */
    align-items: center;
    /* Centraliza horizontalmente */
    justify-content: center;
    /* Centraliza verticalmente no contêiner */
    height: 100%;
    /* Faz com que o div ocupe toda a altura disponível do contêiner pai */
    padding: 10px;
    /* Adiciona algum espaçamento interno */
    background-color: rgb(126, 126, 255);
    /* Exemplo de cor de fundo */
    border-radius: 4px;
    /* Adiciona bordas arredondadas, se desejar */
    cursor: pointer;
    /* Adiciona um cursor de mão para indicar que é clicável */
}

.swipe-container-command {
    color: #ffff;
    height: 100%;
    margin-top: 5px;
}

.commando-form-service-date .p-calendar .p-inputtext {
    height: 58.8px;
    margin-top: 6.5px;
}