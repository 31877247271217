.error-page {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #f8f9fa;
    font-family: 'Arial', sans-serif;
  }
  
  .error-container {
    text-align: center;
    max-width: 500px;
    padding: 20px;
    background: #fff;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
  }
  
  .error-code {
    font-size: 6rem;
    font-weight: bold;
    color: #ff6b6b;
    margin: 0;
  }
  
  .error-message {
    font-size: 2rem;
    margin: 10px 0;
    color: #343a40;
  }
  
  .error-description {
    font-size: 1rem;
    margin-bottom: 20px;
    color: #6c757d;
  }
  
  .error-button {
    display: inline-block;
    padding: 10px 20px;
    font-size: 1rem;
    color: #fff;
    background-color: #007bff;
    border: none;
    border-radius: 4px;
    text-decoration: none;
    transition: background-color 0.3s;
  }
  
  .error-button:hover {
    background-color: #0056b3;
  }