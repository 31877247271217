$primary-color: #fff;
$secondary-color: #0a0908;
$terceary-color: #30303f;
$grey-color: #8c8e8f;
$grey-secondary: #d4d4d4;
$input-background-color: #f5f5f5;

///////// V2

$hallapp-primary-color: #080726;
$hallapp-secondary-color: #4718ad;
$page-back-color: #F2F2F2;
$text-color: #080726;
$hallapp-primary-gradient-color: linear-gradient(90deg, #7d4ee0, #5627bc);