@import '~bootstrap/scss/bootstrap';

@import 'colors.scss';
@import 'react-toastify/dist/ReactToastify.css';
@import 'primereact/resources/themes/lara-light-cyan/theme.css';
@import 'bootstrap-icons/font/bootstrap-icons.css';

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
  background-color: 'blue';
  
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.hallapp-title {
  font-size: 25px;
  color: $text-color;
}

.hallapp-subtitle {
  font-size: 17px;
  color: $text-color;
}

.text-color {
  color: $text-color;
}

p {
  color: $text-color;
}
.custom-input-focus:focus {
  border-color: $primary-color;
  box-shadow: 0 0 0 0.2rem rgba($primary-color, 0.25); /* Sombra com cor primária e transparência */
}

.link {
  color: #0056b3;
}

.container-wrapper {
  display: flex;
  justify-content: center; 
  align-items: center;
}

h1 {
  color: $text-color;
}

.hallapp-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.hallapp-content {
  padding: 20px;
}

.hallapp-form {
  width: 100% !important;
}