@import '../../colors.scss';

.custom-badge-waiting-for-payment {
  background-color: #f7ce55 !important;
  color: rgb(0, 0, 0);
}

.p-calendar .p-inputtext {
  height: 38.8px;
  margin-top: 6.5px;
}

.select-command-filter {
  height: 38.8px;
  margin-top: 6.5px;
}

.my-balance-commands {
  background-color: rgb(85, 172, 85);
  color: #fff;
  text-align: center;
  align-items: center;
}

.my-wallet-command {
  background: $hallapp-primary-gradient-color;
  height: 180px;
  position: absolute;
  left: 0;
  right: 0;
  top: 0 !important;
  color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; 
}
