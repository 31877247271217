@import '../../colors.scss';

.login-password-recovery {
  text-align: end;
  color: $hallapp-secondary-color;
}

.create-account-txt {
  color: $hallapp-secondary-color;
  font-weight: bold;
}