.top-section {
    background-color: #bec5cc;
    color: #fff;
    height: 220px;
    text-align: center;
    position: relative;
}
.bottom-section {
    background-color: #ffff;
    padding: 80px 10px;
    height: 100% !important;
}
.profile-pic {
    width: 150px !important;
    height: 150px !important;
    border-radius: 50%;
    overflow: hidden;
    margin: 0 auto;
    border: 4px solid #fff;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    position: relative;
    top: 45px; /* Move para invadir a parte inferior */
}
.profile-pic img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.title {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 10px;
    margin-top: 30px;
}
.subtitle {
    font-size: 16px;
    color: #555;
    margin-bottom: 20px;
}
.description {
    font-size: 16px;
    line-height: 1.6;
    max-width: 600px;
    margin: 0 auto 20px;
}
.button {
    display: inline-block;
    background-color: #007bff;
    color: #fff;
    padding: 10px 20px;
    text-decoration: none;
    border-radius: 5px;
    font-size: 16px;
}
.button:hover {
    background-color: #0056b3;
}