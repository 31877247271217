.auth-container-page {
    background: linear-gradient(90deg, #7d4ee0, #5627bc);
    height: 100% !important;
    position: absolute;
    width: 100%;
}

.auth-app-logo {
    text-align: center;
    margin-top: 60px;
}

.auth-content {
    background-color: #fff;
    width: 100%;
    height: calc(100% - 185px);
    margin-top: 30px;
    border-radius: 30px 30px 0 0;
    text-align: center;
  }