.container-create-account {
    text-align: center;
    margin-top: 10%;
    width: 60%;
    justify-content: center;  
}

@media (max-width: 768px) {
    .container-create-account {
      margin-top: 5%;
      width: 100%;
    }
  }
  