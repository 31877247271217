@import '../../colors.scss';

.custom-input {
    background-color: #ffff;
    border-color: $grey-color;
    color: $text-color;

}

label {
    color: $text-color !important;
}

.form-floating>label::after {
    background-color: transparent !important;
    color: $text-color !important;
}

.custom-input:focus {
    background-color: $input-background-color;
    border-color: $text-color;
    color: $text-color !important;
}

.input-disabled {
    border-color: $text-color;
    background-color: #e9e6e6 !important;
    cursor: not-allowed;
}

.react-datepicker__day {
    width: 2rem;
    /* Ajusta a largura dos dias */
    height: 2rem;
    /* Ajusta a altura dos dias */
    line-height: 2rem;
    /* Centraliza o texto verticalmente */
}

.p-calendar {
    z-index: 10000 !important;
    /* Ajusta conforme necessário */
}