/* Estilos para o toast de erro */
.custom-toast-error {
    background-color: #f44336; /* Cor de fundo vermelho */
    color: white; /* Cor do texto */
    font-weight: bold; /* Texto em negrito */
}

.custom-toast-body {
    font-size: 14px; /* Tamanho da fonte */
}

/* Estilos para o toast de sucesso */
.custom-toast-success {
    background-color: #4caf50; /* Cor de fundo verde */
    color: white; /* Cor do texto */
    font-weight: bold; /* Texto em negrito */
}

.custom-toast-body {
    font-size: 14px; /* Tamanho da fonte */
}

.custom-toast-progress {
    background: #ebe8e8; /* Cor da barra de progresso */
}