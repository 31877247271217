.swipe-delete {
    display: flex;
    flex-direction: column;
    /* Empilha o ícone e o texto verticalmente */
    align-items: center;
    /* Centraliza horizontalmente */
    justify-content: center;
    /* Centraliza verticalmente no contêiner */
    height: 100%;
    /* Faz com que o div ocupe toda a altura disponível do contêiner pai */
    padding: 10px;
    /* Adiciona algum espaçamento interno */
    background-color: rgb(255, 132, 102);
    ;
    /* Exemplo de cor de fundo */
    border-radius: 4px;
    /* Adiciona bordas arredondadas, se desejar */
    cursor: pointer;
    /* Adiciona um cursor de mão para indicar que é clicável */
}

.swipe-edit {
    display: flex;
    flex-direction: column;
    /* Empilha o ícone e o texto verticalmente */
    align-items: center;
    /* Centraliza horizontalmente */
    justify-content: center;
    /* Centraliza verticalmente no contêiner */
    height: 100%;
    /* Faz com que o div ocupe toda a altura disponível do contêiner pai */
    padding: 10px;
    /* Adiciona algum espaçamento interno */
    background-color: rgb(126, 126, 255);
    /* Exemplo de cor de fundo */
    border-radius: 4px;
    /* Adiciona bordas arredondadas, se desejar */
    cursor: pointer;
    /* Adiciona um cursor de mão para indicar que é clicável */
}

.swipe-container-category {
    color: #ffff;
    height: 170px;
    margin-top: 0px;
}

.categories-pic {
    width: 80px !important;
    height: 80px !important;
    border-radius: 50%;
    overflow: hidden;
    margin: 0 auto;
    border: 4px solid #fff;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    position: relative;
}
.categories-pic img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}