@import '../../colors.scss';

.hallapp-establishment-modal {
    background-color: $primary-color !important;
    color: $text-color;
}

.swipe-delete-company {
  display: flex;
  flex-direction: column; /* Empilha o ícone e o texto verticalmente */
  align-items: center; /* Centraliza horizontalmente */
  justify-content: center; /* Centraliza verticalmente no contêiner */
  height: 100%; /* Faz com que o div ocupe toda a altura disponível do contêiner pai */
  padding: 10px; /* Adiciona algum espaçamento interno */
  background-color: rgb(255, 132, 102);; /* Exemplo de cor de fundo */
  border-radius: 4px; /* Adiciona bordas arredondadas, se desejar */
  cursor: pointer; /* Adiciona um cursor de mão para indicar que é clicável */
}

.swipe-edit-company {
  display: flex;
  flex-direction: column; /* Empilha o ícone e o texto verticalmente */
  align-items: center; /* Centraliza horizontalmente */
  justify-content: center; /* Centraliza verticalmente no contêiner */
  height: 100%; /* Faz com que o div ocupe toda a altura disponível do contêiner pai */
  padding: 10px; /* Adiciona algum espaçamento interno */
  background-color:  rgb(126, 126, 255); /* Exemplo de cor de fundo */
  border-radius: 4px; /* Adiciona bordas arredondadas, se desejar */
  cursor: pointer; /* Adiciona um cursor de mão para indicar que é clicável */
}

.swipe-container-company{
  color: #ffff;
}
