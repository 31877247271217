.category-pic {
    width: 150px !important;
    height: 150px !important;
    border-radius: 50%;
    overflow: hidden;
    margin: 0 auto;
    border: 4px solid #fff;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    position: relative;
}
.category-pic img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}