@import '../../colors.scss';

.floating-button {
    position: fixed; /* Fixa o botão em relação à janela de visualização */
    bottom: 50px; /* Espaçamento do fundo da tela */
    right: 20px; /* Espaçamento da borda direita da tela */
    width: 56px; /* Largura do botão */
    height: 56px; /* Altura do botão */
    background: $hallapp-primary-gradient-color; /* Cor de fundo do botão */
    color: white; /* Cor do ícone/texto */
    border: none; /* Remove a borda padrão */
    border-radius: 50%; /* Torna o botão circular */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Adiciona uma sombra */
    display: flex; /* Flexbox para centralizar o ícone */
    align-items: center; /* Alinha verticalmente */
    justify-content: center; /* Alinha horizontalmente */
    font-size: 20px; /* Tamanho do ícone */
    cursor: pointer; /* Cursor de mão ao passar o mouse */
    transition: background-color 0.3s, box-shadow 0.3s; /* Transições suaves */
}

.floating-button:hover {
    background-color: #0056b3; /* Cor do botão ao passar o mouse */
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3); /* Sombra mais forte ao passar o mouse */
}

.floating-button:focus {
    outline: none; /* Remove o contorno padrão */
}

.floating-button-secondary{
    position: fixed; /* Fixa o botão em relação à janela de visualização */
    bottom: 120px; /* Espaçamento do fundo da tela */
    right: 20px; /* Espaçamento da borda direita da tela */
    width: 56px; /* Largura do botão */
    height: 56px; /* Altura do botão */
    background-color: $secondary-color; /* Cor de fundo do botão */
    color: white; /* Cor do ícone/texto */
    border: none; /* Remove a borda padrão */
    border-radius: 50%; /* Torna o botão circular */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Adiciona uma sombra */
    display: flex; /* Flexbox para centralizar o ícone */
    align-items: center; /* Alinha verticalmente */
    justify-content: center; /* Alinha horizontalmente */
    font-size: 20px; /* Tamanho do ícone */
    cursor: pointer; /* Cursor de mão ao passar o mouse */
    transition: background-color 0.3s, box-shadow 0.3s; /* Transições suaves */
}

.floating-button-secondary:hover {
    background-color: #0056b3; /* Cor do botão ao passar o mouse */
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3); /* Sombra mais forte ao passar o mouse */
}

.floating-button-secondary:focus {
    outline: none; /* Remove o contorno padrão */
}
