.container-hallapp-wrapper {
    display: flex;
    width: 100%;
  }

  .container-hallapp {
    width: 100%;
  }
  
  /* Estilo específico para dispositivos com telas maiores, como tablets, notebooks, TVs e monitores */
  @media (min-width: 768px) {
    /* Tamanhos de tela para tablets e dispositivos móveis maiores */
    .container-hallapp {
      /* Estilos específicos para tablets e telas maiores */
      margin-top: 10%;
      width: 60%;
      justify-content: center;
    }
  }
  
  @media (min-width: 1024px) {
    /* Tamanhos de tela para notebooks e monitores */
    .container-hallapp {
      /* Estilos específicos para notebooks e monitores */
      margin-top: 10%;
      width: 60%;
      justify-content: center;
    }
  }
  
  @media (min-width: 1200px) {
    /* Tamanhos de tela para TVs e grandes monitores */
    .container-hallapp {
      /* Estilos específicos para TVs e grandes monitores */
      margin-top: 10%;
      width: 60%;
      justify-content: center;
    }
  }