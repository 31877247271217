.swipe-delete-employee {
    display: flex;
    flex-direction: column; /* Empilha o ícone e o texto verticalmente */
    align-items: center; /* Centraliza horizontalmente */
    justify-content: center; /* Centraliza verticalmente no contêiner */
    padding: 10px; /* Adiciona algum espaçamento interno */
    background-color: rgb(255, 132, 102);; /* Exemplo de cor de fundo */
    border-radius: 4px; /* Adiciona bordas arredondadas, se desejar */
    cursor: pointer; /* Adiciona um cursor de mão para indicar que é clicável */
    height: 100%;
  }
  
  .swipe-edit-employee {
    display: flex;
    flex-direction: column; /* Empilha o ícone e o texto verticalmente */
    align-items: center; /* Centraliza horizontalmente */
    justify-content: center; /* Centraliza verticalmente no contêiner */
    background-color:  rgb(126, 126, 255); /* Exemplo de cor de fundo */
    border-radius: 4px; /* Adiciona bordas arredondadas, se desejar */
    cursor: pointer; /* Adiciona um cursor de mão para indicar que é clicável */
    height: 100%;
  }
  
  .swipe-container{
    color: #ffff;
    margin-top: 5px;
  }

  .swipe-container-employee{
    color: #ffffff;
  }
  