@import '../../colors.scss';

.btn-next-category {
    background: $hallapp-primary-gradient-color;
}

.service-employee-checked {
    background: $hallapp-primary-gradient-color;
}

.div-custom-tabs {
    background: $hallapp-primary-gradient-color;
    width: 100%;
    position: fixed;
    top: 56px;
    /* Ajuste esse valor para a altura da sua navbar */
    left: 0;
    border: 0;
    margin: 0 !important;
    /* Remove a margem externa */
    padding: 0 !important;
    /* Remove o preenchimento interno */
    z-index: 10;
    /* Adicione z-index para garantir que fique acima */
}

.custom-tabs .nav-tabs {
    margin: 0;
    /* Ajuste a margem se necessário */
}

.custom-tab {
    background-color: #ffffff;
}

.custom-tabs .nav-link {
    color: white;
    /* Cor do texto das tabs */
}

.custom-tabs .nav-link:hover {
    color: rgb(14, 13, 13);
    /* Cor do texto ao passar o mouse */
}

.custom-tabs {
    width: 100%;
    left: 0;
    border: 0;
    margin: 0 !important;
}

.swipe-delete {
    display: flex;
    flex-direction: column;
    /* Empilha o ícone e o texto verticalmente */
    align-items: center;
    /* Centraliza horizontalmente */
    justify-content: center;
    /* Centraliza verticalmente no contêiner */
    height: 100%;
    /* Faz com que o div ocupe toda a altura disponível do contêiner pai */
    padding: 10px;
    /* Adiciona algum espaçamento interno */
    background-color: rgb(255, 132, 102);
    ;
    /* Exemplo de cor de fundo */
    border-radius: 4px;
    /* Adiciona bordas arredondadas, se desejar */
    cursor: pointer;
    /* Adiciona um cursor de mão para indicar que é clicável */
}

.swipe-edit {
    display: flex;
    flex-direction: column;
    /* Empilha o ícone e o texto verticalmente */
    align-items: center;
    /* Centraliza horizontalmente */
    justify-content: center;
    /* Centraliza verticalmente no contêiner */
    height: 100%;
    /* Faz com que o div ocupe toda a altura disponível do contêiner pai */
    padding: 10px;
    /* Adiciona algum espaçamento interno */
    background-color: rgb(126, 126, 255);
    /* Exemplo de cor de fundo */
    border-radius: 4px;
    /* Adiciona bordas arredondadas, se desejar */
    cursor: pointer;
    /* Adiciona um cursor de mão para indicar que é clicável */
}

.swipe-container-service {
    color: #ffff;
}

.services-pic {
    width: 80px !important;
    height: 80px !important;
    border-radius: 50%;
    overflow: hidden;
    margin: 0 auto;
    border: 4px solid #fff;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    position: relative;
    top: 10px;
}
.services-pic img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}