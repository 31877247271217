@import '../../../colors.scss';

.splash-container {
    display: flex;
    flex-direction: column;
    justify-content: center; 
    align-items: center;
    height: 100vh; 
    text-align: center; 
    background: linear-gradient(90deg, #7d4ee0, #5627bc);
}


.hallapp-text {
    margin-top: 10px;
    font-size: 38px;
    color: rgb(252, 252, 252);
    font-weight: bold;
    animation: zoomInOut 2s ease-in-out infinite;
}

// @keyframes zoomInOut {
//     0% {
//       transform: scale(1); /* Tamanho original */
//     }
//     50% {
//       transform: scale(1.1); /* Aumenta 10% */
//     }
//     100% {
//       transform: scale(1); /* Retorna ao tamanho original */
//     }
//   }
  
//   .appname-container {
//     animation: zoomInOut 2s ease-in-out infinite; 
//   }
